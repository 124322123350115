import React from "react";
import {
	SectionContainerGrey,
	SectionContent,
} from "../../GlobalStyle";


const About = () => (
	<SectionContainerGrey id="about">
		<SectionContent>
			<div>
				<h2 className="h3 lined" >About Me</h2>
			</div>
			<div>
				<p className="lead text-muted">
					{" "}
					I started my career in 2012 in Pretoria, South Africa, where I owned
					and managed a small company providing IT Services to offices and
					private clients. As an Assistant Educator for Computer Application
					Technology at high school level, I facilitated knowledge and skills
					transfer, mainly in computer network, data entry and web design.
				</p>
				<p class="lead text-muted">
					In 2017, seeking in-depth knowledge with regard to programming, I
					successfully passed
					<a
						href="https://www.edx.org/course/cs50s-introduction-computer-science-harvardx-cs50x"
						target="_blank" rel="noreferrer"
					>
						{" "}
						CS50’s ‘Harvard University's introduction to the intellectual
						enterprises of Computer Science and the art of programming’
					</a>{" "}
					through the online learning platform edX.
				</p>
				<p class="lead text-muted">
					Through this course, I gained a robust understanding of computer
					science and programming and could learn in theory and practise how to
					efficiently solve programming problems and how to conceptualize,
					implement, and present a major programming project. The languages
					include C, Python, SQL, and JavaScript plus CSS and HTML.
				</p>
				<p class="lead text-muted">
					Now, in 2022, I'm one of the co-founders of{" "}
					<a href="www.oxidetech.dev" target="_blank" rel="noreferrer" >
						Oxide Digital Technology
					</a>
				</p>
			</div>
		</SectionContent>
	</SectionContainerGrey>
);

export default About;
