import React from "react";
import { Grid, Avatar } from "@mui/material";
import { SectionContainerGrey, SectionContent } from "../../GlobalStyle";
import SkillsLogo from "./LogoImages";
import { makeStyles } from "@mui/styles";

const useStyles = makeStyles({
  LogoAvatar: {
    margin: 10,
  },

  mb: {
    marginBottom: 40,
  },
});

const TechExpertise = () => {
  const classes = useStyles();
  return (
    <SectionContainerGrey id="technologies">
      <SectionContent>
        <div className={classes.mb}>
          <h2 className="h3 lined">TECHNOLOGY EXPERTISE</h2>
        </div>
        <Grid
          container
          direction="row"
          justifyContent="center"
          alignItems="center"
          spacing={4}
        >
          {SkillsLogo.map((logo) => {
            return (
              <Grid item xs={6} sm={3} md={3} lg={3} xl={3} key={logo.id}>
                <Grid
                  container
                  direction="column"
                  justifyContent="center"
                  // spacing={1}
                  alignItems="center"
                >
                  <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                    <Avatar
                      alt={logo.title}
                      src={logo.img}
                      sx={{ width: 65, height: 65 }}
                      variant="string"
                      className={classes.LogoAvatar}
                    />
                  </Grid>
                  <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                    <p>{logo.title}</p>
                  </Grid>
                </Grid>
              </Grid>
            );
          })}
        </Grid>
      </SectionContent>
    </SectionContainerGrey>
  );
};

export default TechExpertise;
