import NavBar from "./components/NavigationBar/NavBar";
import About from "./components/About/About";
import TechExpertise from "./components/TechExpertise/TechExpertise";
// import Education from "./components/Education/Education";
import { GlobalStyle } from "./GlobalStyle";
import Header from "./components/Header/Header";
import Expertise from "./components/Expertise/Expertise";
import Experience from "./components/Experience/Experience";
import Contact from "./components/Contact/Contact";
import Footer from "./components/Footer/Footer";

function App() {
  return (
    <div>
      <NavBar />
      <Header />
      <About />
      <Expertise />
      <TechExpertise />
      {/* <Education /> */}
      <Experience />
      <Contact />
      <Footer />

      <GlobalStyle />
    </div>
  );
}

export default App;
