const SkillsLogo = [
  {
    id: 1,
    img: require("../../Assets/images/SkillsLogo/Python_logo.png"),
    title: "Python",
  },
  {
    id: 2,
    img: require("../../Assets/images/SkillsLogo/C_logo.png"),
    title: "C",
  },
  {
    id: 3,
    img: require("../../Assets/images/SkillsLogo/JS_logo.png"),
    title: "JavaScript",
  },
  {
    id: 4,
    img: require("../../Assets/images/SkillsLogo/Swift_logo.png"),
    title: "Swift",
  },
  {
    id: 5,
    img: require("../../Assets/images/SkillsLogo/Dart_logo.png"),
    title: "Dart",
  },
  {
    id: 6,
    img: require("../../Assets/images/SkillsLogo/Django_logo.png"),
    title: "Django",
  },
  {
    id: 7,
    img: require("../../Assets/images/SkillsLogo/Flutter_logo.png"),
    title: "Flutter",
  },
  
  {
    id: 8,
    img: require("../../Assets/images/SkillsLogo/React_logo.png"),
    title: "React",
  },

  {
    id: 9,
    img: require("../../Assets/images/SkillsLogo/Postgres_logo.png"),
    title: "PostgreSQL",
  },
  {
    id: 10,
    img: require("../../Assets/images/SkillsLogo/Kubernetes_logo.png"),
    title: "Kubernetes",
  },
 
  {
    id: 11,
    img: require("../../Assets/images/SkillsLogo/Aws_logo.png"),
    title: "Amazon Web Services",
  },
  {
    id: 12,
    img: require("../../Assets/images/SkillsLogo/DO_logo.png"),
    title: "DigitalOcean",
  },
];

export default SkillsLogo;
