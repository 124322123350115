const Links = [
  {
    id: 1,
    url: "/",
    text: "HOME",
  },
  {
    id: 2,
    url: "#about",
    text: "ABOUT",
  },
  {
    id: 3,
    url: "#expertise",
    text: "EXPERTISE",
  },
  {
    id: 4,
    url: "#technologies",
    text: "TECHNOLOGIES",
  },
  // {
  //   id: 5,
  //   url: "/portfolio",
  //   text: "PORTFOLIO",
  // },
  {
    id: 5,
    url: "#experience",
    text: "EXPERIENCE",
  },
  // {
  //   id: 7,
  //   url: "/education",
  //   text: "EDUCATION",
  // },
  {
    id: 7,
    url: "#contact",
    text: "CONTACT",
  },
];
export default Links;
