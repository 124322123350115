import React from "react";
import { SectionContainerGrey, SectionContent } from "../../GlobalStyle";
import { BusinessTalk, LetsTalk, Contacts } from "./Contact.styled";
import { Grid } from "@mui/material";
import Box from "@mui/material/Box";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import PhoneIphoneIcon from "@mui/icons-material/PhoneIphone";
import EmailIcon from "@mui/icons-material/Email";
import oxideLogo from "../../Assets/images/oxide.png";
// import Form from "./Contact.form";
import "./Contact.css";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";


export default function Contact() {
	return (
		<SectionContainerGrey id="contact">
			<SectionContent>
				<BusinessTalk>
					<LetsTalk>let's talk business</LetsTalk>
					<Contacts className="lined"> contact</Contacts>
					<Grid
						container
						justifyContent={"space-between"}
						spacing={2}
						sx={{ paddingTop: 6 }}
					>
						{/* <Grid item lg={6} md={6} sm={6}>
              <Form />
            </Grid> */}
						<Grid item lg={3} sm={12} xs={12} md={6} textAlign={"center"}>
							<Card variant="outlined">
								<Box>
									<CardContent>
										<p>
											<LocationOnIcon
												sx={{ fontSize: 40 }}
												className="icon"
											></LocationOnIcon>
										</p>

										<h4 className="h4 hover text-uppercase">Location</h4>
										<p className="lead text-small">Bukavu, DRC</p>
									</CardContent>
								</Box>
							</Card>
						</Grid>
						<Grid item lg={3} sm={12} xs={12} md={6} textAlign={"center"}>
							<Card variant="outlined">
								<Box>
									<CardContent>
										<p>
											<PhoneIphoneIcon
												sx={{ fontSize: 40 }}
												className="icon"
											></PhoneIphoneIcon>
										</p>

										<h4 className="h4 hover text-uppercase">Phone</h4>
										<p className="lead text-small">+243 819 810 142</p>
									</CardContent>
								</Box>
							</Card>
						</Grid>
						<Grid item lg={3} sm={12} xs={12} md={6} textAlign={"center"}>
							<Card variant="outlined">
								<a
									href="mailto:info@joeshindano.com"
									target="_blank"
									rel="noreferrer"
								>
									<Box>
										<CardContent>
											<p>
												<EmailIcon
													sx={{ fontSize: 40 }}
													className="icon"
												></EmailIcon>
											</p>

											<h4 className="h4 hover text-uppercase">Email</h4>
											<p className="lead text-small">info@joeshindano.com</p>
										</CardContent>
									</Box>
								</a>
							</Card>
						</Grid>
						<Grid item lg={3} sm={12} xs={12} md={6} textAlign={"center"}>
							<Card variant="outlined">
								<a
									href="https://oxide-digital.com/"
									target="_blank"
									rel="noreferrer"
								>
									<Box>
										<CardContent>
											<p>
												<img
													src={oxideLogo}
													className="img"
													alt="oxide Logo "
												/>
											</p>

											<h4 className="h4 hover text-uppercase">Oxide</h4>
											<p className="lead text-small">Digital Technology</p>
										</CardContent>
									</Box>
								</a>
							</Card>
						</Grid>
					</Grid>
				</BusinessTalk>
			</SectionContent>
		</SectionContainerGrey>
	);
}
