import React from "react";
import {
	SectionContainerWhite,
	SectionContent,
} from "../../GlobalStyle";

const Experience = () => {
	return (
		<SectionContainerWhite id="experience">
			<SectionContent>
				<div>
					<h2 className="h2 text-uppercase lined">EXPERIENCE</h2>
				</div>
				<div>
					<ul class="timeline">
						<li class="timeline-item ml-3 pb-4">
							<div class="row">
								<div class="col-lg-5 mb-4 mb-lg-0">
									<p class="font-weight-bold mb-2 text-small duration-style">
										July 2020 - Now{" "}
									</p>
									<h2 class="h5 mb-0 text-uppercase">
										OXIDE DIGITAL TECHNOLOGY
									</h2>
									<p class="lead text-small mb-0">CEO | Co-Founder</p>
									<span class="small text-muted">DRC</span>
								</div>
								<div class="col-lg-7 p-0">
									<ul class="text-muted lead">
										<li>
											As CEO of Oxide Digital Technology, I am responsible for
											the performance of the company as dictated by the overall
											strategy of the Board of Directors. I report directly to
											the Board of Directors.
										</li>
										<li>
											Managing the company's financial actions and risks. Duties
											include monitoring cash flow, financial planning, record
											keeping, financial reporting, and analyzing the company's
											financial strengths and weaknesses and proposing
											corrective actions
										</li>
										<li>
											Successfully formulate and implement company policy.
										</li>
										<li>
											Directing strategy towards profitable growth and operation
											of the company
										</li>
										<li>
											Develop strategic operational plans that reflect the
											longer term goals and priorities established by the Board
											of Directors.
										</li>
										<li>
											Ensure that operational objectives and performance
											standards are not only understood, but also appropriate by
											management and other employees.
										</li>
										<li>
											Maintain the operational performance of the company and be
											fully accountable to the Board of Directors for all
											operations of the company.
										</li>
										<li>
											Represent the company with key customers and trade
											associations and be responsible for negotiating high level
											contracts.
										</li>
										<li>
											Validate or write proposals or oversee written proposals.
										</li>
									</ul>
								</div>
							</div>
						</li>

						<li class="timeline-item ml-3 pb-4">
							<div class="row">
								<div class="col-lg-5 mb-4 mb-lg-0">
									<p class="font-weight-bold mb-2 text-small duration-style">
										Feb 2017 - Oct 2018{" "}
									</p>
									<h2 class="h5 mb-0 text-uppercase">CONSULTANT</h2>
									<p class="lead text-small mb-0">Active Software Developer</p>
									<span class="small text-muted">Remote</span>
								</div>
								<div class="col-lg-7 p-0">
									<ul class="text-muted lead">
										<li>
											Development and design of web applications using Python
											Flask and Django
										</li>
										<li>Working with a team developing mobile applications</li>
										<li>
											Consulting with companies and organisation to implement
											latest technology and digitalize their businesses and
											services
										</li>
										<li>
											Assistance in quantitative and qualitative data analysis
											in different project evaluations and studies
										</li>
									</ul>
								</div>
							</div>
						</li>

						<li class="timeline-item ml-3 pb-4">
							<div class="row">
								<div class="col-lg-5 mb-4 mb-lg-0">
									<p class="font-weight-bold mb-2 text-small duration-style">
										March 2013 - Dec 2013{" "}
									</p>
									<h2 class="h5 mb-0 text-uppercase">ED-U-COLLEGE</h2>
									<p class=" lead text-small mb-0">Educator</p>
									<span class="small text-muted">Freestate, South Africa</span>
								</div>
								<div class="col-lg-7 p-0">
									<ul class="text-muted lead">
										<li>
											High School (grade 10, 11, 12): Courses on Computer
											Application Technology & Life Orientation
										</li>
									</ul>
								</div>
							</div>
						</li>

						<li class="timeline-item ml-3 pb-4">
							<div class="row">
								<div class="col-lg-5 mb-4 mb-lg-0">
									<p class="font-weight-bold mb-2 text-small duration-style">
										December 2012 - July 2014{" "}
									</p>
									<h2 class="h5 mb-0 text-uppercase">
										LIGHT AFRICA INVESTMENT TRADING
									</h2>
									<p class="lead text-small mb-0">
										Shareholder and Managing Director
									</p>
									<p class="lead text-small mb-0">
										A company providing IT Services (hardware, software,
										networks, web design, video and photo editing) to small and
										medium-sized enterprises and individuals.
									</p>
									<span class="small text-muted">Pretoria, South Africa</span>
								</div>
								<div class="col-lg-7 p-0">
									<ul class="text-muted lead">
										<li>Business development and strategic planning;</li>
										<li>
											Financial and business administration, including weekly
											and monthly activity and financial reports;
										</li>
										<li>Provision of technical services;</li>
										<li>
											Establishment of internal work plans for staff and
											collaborators.
										</li>
									</ul>
								</div>
							</div>
						</li>
					</ul>
				</div>

				{/* <Grid container >
					<Grid container xs={1}>
						<h6>line</h6>
					</Grid>
					<Grid container xs={11}>
						<Grid container xs={12} sm={6} md={6} lg={6} xl={6}>
							<Grid item>
								<h6>Left</h6>
							</Grid>
						</Grid>
						<Grid container xs={12} sm={6} md={6} lg={6} xl={6}>
							<Grid item>
								<h6>Right</h6>
							</Grid>
						</Grid>
					</Grid>
				</Grid> */}
			</SectionContent>
		</SectionContainerWhite>
	);
};

export default Experience;
