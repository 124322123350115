import { createGlobalStyle } from "styled-components";

import styled from "styled-components";

// STYLES OF WEBSITE SECTIONS
export const SectionContainerGrey = styled.div`
	background: var(--greyColor);
	padding: 6.5rem 0;
	overflow: hidden;
	position: relative;
	margin: 0 auto;
`;
export const SectionContainerWhite = styled.div`
	background: var(--white);
	padding: 6.5rem 0;
	margin: 0 auto;
`;
export const SectionFooterContainerWhite = styled.div`
	background: var(--white);
	padding-top: 5.5rem;
	margin: 0 auto;
`;

export const SectionContent = styled.div`
	width: 80%;
	margin: 0 auto;
`;
export const SectionFooterContent = styled.div`
    text-align: center;
	width: 50%;
	margin-left: auto;
	margin-right: auto;
`;
export const HiroSection = styled.div`
	width: 100%;
	margin: 0 auto;
	overflow: hidden;
	background: black;
`;
export const HiroSectionContent = styled.div`
	width: 80%;
	margin: 0 auto;
	padding: 6.5rem 0;
	background: black;
`;

// GLOBAL STYLE
export const GlobalStyle = createGlobalStyle`

    :root {
        --maxWidth: 1280px;
        --text-xl: 5 rem;
        --text-lg: 3.5 rem;
        --text-sm: 0.875rem;
        --font-weight-light: 300;
        --font-weight-regular: 400;
        --font-weight-medium: 500;
        --font-weight-bold: 800;
        --red: #ff3f3f;
        --bg-cover: #2E3349BF;
        --greyColor: #f8f9fa;
        --white: #fff;
    }

    *, ::after, ::before {
        font-family: 'Work Sans', sans-serif;
        box-sizing: border-box;
        
    }

    body {
        font-size: 1rem;
        font-weight: 400;
        line-height: 1.5;
        color: #2E3349;
        background-color: #fff;
        overflow-x: hidden;
      }
    


// GLOBAL TEXT STYLES
    h1,
    h2,
    h3,
    h4,
    h5,
    h6{
        margin-bottom: 0.5rem;
        font-family: inherit;
        font-weight: 800;
        line-height: 1.2;
        color: inherit;
    }
    
    .h1 {
        font-size: 3rem;
    }
    
    .h2 {
        font-size: 2.5rem;
    }
    
    .h3{
        font-size: 1.75rem;
      
    }
    
    .h4 {
        font-size: 1.5rem;
    }
    
    .h5 {
        font-size: 1.25rem;
    }
    
    .h6 {
        font-size: 1rem;
    }
    
    a {
        color: inherit;
        text-decoration: none;
    }
      
    a:hover, a:focus {
    color: #f20000;
    text-decoration: underline;
    }
    
    .text-muted {
        color: #5c6063 !important;
    }

    .lead {
        font-size: 1.25rem;
        font-weight: 300;
    }

    .text-uppercase {
    text-transform: uppercase;
    letter-spacing: 0.1em;
    letter-spacing: 0.1em;
    }

    .text-small {
        font-size: 0.9rem !important;
    }

    .small {
        font-size: 80%;
        font-weight: 400;
    }

    .font-weight-bold {
        font-weight: 800 !important;
    }

    .duration-style {
    margin: 0;
    padding: 0;
    color: var(--red);
    }

    .textRedTitle {
    color: #ff3f3f !important;
    font-weight: 800 !important;
    font-family: inherit;
    font-size: 90%;
    letter-spacing: 0.3em;
    margin-top: 0;
     text-transform: uppercase !important;

    }

    .BigtextRedTitle {
        color: #2e3349;
        font-family: inherit;
        font-weight: 800;
        line-height: 1.2;
        font-size: 240%;
        letter-spacing: 0.1em;
    }



// END GLOBAL TEXT STYLES



// THE LINES UNDER HEADINGS 
      .lined {
        display: inline-block;
        position: relative;
        padding-bottom: 0.7rem;
      }
      
      .lined::before {
        content: '';
        display: block;
        position: absolute;
        bottom: 0;
        left: 0;
        width: 3rem;
        height: 2px;
        background: #ff3f3f;
      }
      
      .lined::after {
        content: '';
        display: block;
        position: relative;
        bottom: -5px;
        left: 0;
        width: 5rem;
        height: 3px;
        background: #ff3f3f;
      }
      
// END LINES UNDER HEADINGS


// TIMELINE

    /* Timeline style */
    ul.timeline {
    list-style-type: none;
    position: relative;
    padding-left: 1.5rem;
    }

    /* Timeline vertical line */
    ul.timeline:before {
    content: " ";
    background: #ededed;
    display: inline-block;
    position: absolute;
    left: 16px;
    width: 4px;
    height: 100%;
    z-index: 400;
    border-radius: 1rem;
    }

    .timeline:after {
    content: " ";
    background: #ff3f3f;
    display: inline-block;
    position: absolute;
    bottom: 0;
    left: 9px;
    width: 16px;
    height: 16px;
    border-radius: 50%;
    border: 3px solid #fff;
    z-index: 400;
    }

    /* Timeline list items */
    .timeline-item {
    margin: 20px 0 20px 16px;
    padding:  0 0 24px;
    }

    /* Timeline item circle marker */
    li.timeline-item::before {
    content: " ";
    background: #ff3f3f;
    display: inline-block;
    position: absolute;
    left: 10px;
    width: 16px;
    height: 16px;
    border-radius: 50%;
    border: 3px solid #fff;
    z-index: 400;
    }

    // END TIMELINE



    // SPACING
    .mb-2, .my-2 {
        margin-bottom: 0.5rem!important;
    }

    .mb-0, .my-0 {
        margin-bottom: 0!important;
    }

    .pb-4, .py-4 {
        padding-bottom: 1.5rem!important;
    }

    .ml-3, .mx-3 {
        margin-left: 1rem!important;
    }

    .mb-4, .my-4 {
        margin-bottom: 1.5rem!important;
    }

    .col, .col-1, .col-10, .col-11, .col-12, .col-2, .col-3, .col-4, .col-5, .col-6, .col-7, .col-8, .col-9, .col-auto, .col-lg, .col-lg-1, .col-lg-10, .col-lg-11, .col-lg-12, .col-lg-2, .col-lg-3, .col-lg-4, .col-lg-5, .col-lg-6, .col-lg-7, .col-lg-8, .col-lg-9, .col-lg-auto, .col-md, .col-md-1, .col-md-10, .col-md-11, .col-md-12, .col-md-2, .col-md-3, .col-md-4, .col-md-5, .col-md-6, .col-md-7, .col-md-8, .col-md-9, .col-md-auto, .col-sm, .col-sm-1, .col-sm-10, .col-sm-11, .col-sm-12, .col-sm-2, .col-sm-3, .col-sm-4, .col-sm-5, .col-sm-6, .col-sm-7, .col-sm-8, .col-sm-9, .col-sm-auto, .col-xl, .col-xl-1, .col-xl-10, .col-xl-11, .col-xl-12, .col-xl-2, .col-xl-3, .col-xl-4, .col-xl-5, .col-xl-6, .col-xl-7, .col-xl-8, .col-xl-9, .col-xl-auto {
        position: relative;
        width: 100%;
        padding-right: 5px;
        padding-left: 5px;
    }

// END SPACING





// MEDIA QUERIES

@media (min-width: 992px) {
    .mb-lg-0, .my-lg-0 {
        margin-bottom: 0!important;
    }

    .col-lg-5 {
        -ms-flex: 0 0 41.666667%;
        flex: 0 0 41.666667%;
        max-width: 41.666667%;
    }

    .row {
        /* display: -ms-flexbox; */
        display: flex;
        /* -ms-flex-wrap: wrap;  */
        /* flex-wrap: wrap; */
        padding: 0;
        margin: 0;
        /* margin-right: -15px; */
        /* margin-left: -15px; */
    }

}


`;
