import React from "react";
import { Grid } from "@mui/material";
import {
	SectionFooterContainerWhite,
	SectionFooterContent,
} from "../../GlobalStyle";
import "./Footer.css";
import { SocialIcon } from "react-social-icons";

const Footer = () => {
	return (
		<SectionFooterContainerWhite>
			<SectionFooterContent>
				<Grid
					container
					direction="column"
					// alignItems="center"
					// justifyContent="center"
				>
					<Grid item xs={12} sm={12} md={3} lg={3} xl={3}>
						<h2 class="BigtextRedTitle text-uppercase mb-0">
							Jonathan Shindano{" "}
						</h2>
						<h5 class="textRedTitle text-uppercase mb-0">Software Developer</h5>
						<p class="text-muted my-4">
							I’m a professional developer and I mostly work professionally in
							Python. Beside programming and entrepreneurship I am insterested
							in sports (running, boxing, etc.)
						</p>
						<ul class="list-inline mb-0">
							<li class="list-inline-item">
								<SocialIcon url="https://www.linkedin.com/in/jonathan-shindano/" />
							</li>
							<li class="list-inline-item">
								<SocialIcon url="https://twitter.com/joeshindano" />
							</li>
							<li class="list-inline-item">
								<SocialIcon url="https://github.com/Joerockafella" />
							</li>
						</ul>
						<hr
							style={{
								height: 1,
								width: "100%",
								margin: "2rem",
							}}
						/>
					</Grid>
				</Grid>
			</SectionFooterContent>
			<Grid container className="copyrights">
				<Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
					<div class="py-4 border-top text-center">
						<p class="mb-0 text-muted py-2">
							&copy; {new Date().getFullYear()} Joe Shindano. All rights
							reserved.
						</p>
					</div>
				</Grid>
			</Grid>
		</SectionFooterContainerWhite>
	);
};

export default Footer;
