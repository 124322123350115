import { useState } from "react";
import ViewHeadlineIcon from "@mui/icons-material/ViewHeadline";
import { Togglebutton } from "../../components/NavigationBar/styled";
import CloseIcon from "@mui/icons-material/Close";
import Links from "./LinksData";
import "./navbar.css";
import ImgLogo from "../../Assets/images/jslogo.png";

const NavBar = () => {
  const [showlinks, setshowlinks] = useState(false);
  const [scrollTriggered, setScrollTriggered] = useState(false);

  // Set the scroll triggered state to true when the user scrolls
  const changeNavbarColor = () =>{
    if(window.scrollY >= 80){
      setScrollTriggered(true);
    }
    else{
      setScrollTriggered(false);
    }
 };
 window.addEventListener('scroll', changeNavbarColor);

  return (
    <nav className={scrollTriggered ? "navWhite" : "navBlack"}>
      <div className="nav-center">
        <div className="nav-logo-and-toggleBtn">
        {scrollTriggered ?
          <img src={ImgLogo} alt="JS logo" />
          : null}

          <Togglebutton onClick={() => setshowlinks(!showlinks)}>
            {!showlinks ? <ViewHeadlineIcon /> : <CloseIcon />}
          </Togglebutton>
        </div>

        <div
          className={`${
            showlinks ? "links-container show-container" : "links-container"
          }`}
        >
          <ul className="links">
            {Links.map((link) => {
              const { id, url, text } = link;
              return (
                <li key={id}>
                  {/* If srcoll is triggered change navigation link color to black */}
                  {scrollTriggered ?
                  <a className={"nav-link"} href={url}>
                    {text}
                  </a>
                  :
                  <a className={ "nav-link navLinkColorWhite"} href={url}>
                    {text}
                  </a>}
                </li>
              );
            })}
          </ul>
        </div>
      </div>
    </nav>
  );
};
export default NavBar;
