import React from "react";
import "./Header.css";
import { HiroSection, HiroSectionContent } from "../../GlobalStyle";
import { Grid } from "@mui/material";

export default function Header() {
	return (
		<>
			<HiroSection>
				<HiroSectionContent>
					<Grid container>
						<Grid item lg={6} md={6} sm={12}>
							<img
								src={require("../../Assets/images/JoeDarkBg.jpg")}
								alt="Joe"
								className="joeImg"
							/>
						</Grid>
						<Grid item lg={6} md={6} sm={12} className="hiro">
							<h2 className="h4 color"> Hi, I am </h2>
							<h1 className="font">
								{" "}
								JOE <span className="red">SHINDANO</span>
							</h1>
							<h2 className="white">Software Engineer</h2>
							<p className="p lead whites">
								{" "}
								I am passionate about researching, designing, implementing, and
								managing software systems to help companies operate more
								efficiently and provide the best possible services to customers.
								I also identify areas for improvement in existing systems and
								then develop those changes.
							</p>
						</Grid>
					</Grid>
				</HiroSectionContent>
			</HiroSection>
		</>
	);
}
