import styled from "styled-components";

export const SectionContent = styled.div`
  width: 80%;
  margin: 0 auto;
`;
export const SectionContainerGrey = styled.div`
  background: var(--greyColor);
  padding: 6.5rem 0;
  overflow: hidden;
  position: relative;
  margin: 0 auto;
`;
export const BusinessTalk = styled.header`
  padding-bottom: 1.5rem !important;
  margin-bottom: 3rem !important;
  display: block;
`;
export const LetsTalk = styled.p`
  color: #ff3f3f !important;
  font-weight: 800 !important;
  letter-spacing: 0.3em;
  text-transform: uppercase !important;
`;
export const Contacts = styled.h2`
  margin-bottom: 0.5rem;
  font-family: inherit;
  font-weight: 800;
  line-height: 1.2;
  color: inherit;
  text-transform: uppercase !important;
  letter-spacing: 0.1em;
`;
