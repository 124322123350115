import React from "react";
import { Grid } from "@mui/material";
import {
	ExpertiseContainer,
	ExpertiseContent,
	Title,
	Content,
	Ecommmerce,
	Design,
	MobileApplication,
	WebDevelopment,
} from "./Expertise.styled";
import "./Expertise.css";
export default function Expertise() {
	return (
		<ExpertiseContainer id="expertise">
			<ExpertiseContent>
				<Title className="lined text-uppercase"> expertise</Title>
				<Content>
					<Grid container>
						<Grid item lg={6}>
							<Ecommmerce>
								<h3 className="h4">
									{" "}
									<span className="text">01</span>Cloud Computing
								</h3>
								<p className=" paragraphe text-small lead ">
									Provide and host services over the Internet, such as servers,
									storage, databases, networks, software and analytics.
								</p>
							</Ecommmerce>
							<Design>
								<h3 className="h4 bold">
									{" "}
									<span className="text">03</span>System Design
								</h3>
								<p className=" paragraphe text-small lead ">
									{/* Having a website for your business allows customers to
                  directly interact with your company or services from wherever
                  they are and whenever they please. Your website is not only
                  your extended online business card, but can help you to propel
                  your customer relations and selling of products and services
                  to the digital level. Therefore, every business should have a
                  website that is adapted to their vision, product and services
                  and specific business needs. I help you by understanding what
                  you need and design aesthetic, responsive and professional
                  business websites.  */}
									Build interactive and visual design principles on all
									platforms such as web, mobile applications and other systems
									with the best UI and UX service delivery process.
								</p>
							</Design>
						</Grid>
						<Grid item lg={6}>
							<MobileApplication>
								<h3 className="h4">
									{" "}
									<span className="text">02</span> Mobile Applications
								</h3>
								<p className=" paragraphe text-small lead ">
									Today, many companies are looking to have mobile applications,
									in order to interact with their customers or to provide
									certain digital services. I work with an excellent team of
									developers with the necessary skills to develop user-friendly
									and consumer-oriented mobile applications.
								</p>
							</MobileApplication>
							<WebDevelopment>
								<h3 className="h4">
									{" "}
									<span className="text">04</span> Web Development
								</h3>
								<p className=" paragraphe text-small lead ">
									I specialize in web application technologies for small, medium
									and large enterprises such as schools, government
									institutions, hospitals, retail applications, etc.
								</p>
							</WebDevelopment>
						</Grid>
					</Grid>
				</Content>
			</ExpertiseContent>
		</ExpertiseContainer>
	);
}
