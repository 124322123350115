import styled from "styled-components";

export const ExpertiseContainer = styled.div`
  padding: 98px 0px;
  margin: 0 auto;
  /* background: var(--greyColor); */
`;
export const ExpertiseContent = styled.div`
  width: 80%;
  margin: 0 auto;
`;
export const Title = styled.h2`
  font-size: 2.5rem;
  font-size: 40px;
  margin: 0px 0px 8px;
  padding: 0px 0px 11.2px;
`;
export const Content = styled.div``;
export const Ecommmerce = styled.div`
  margin-bottom: 3rem !important;
  margin: 0px 0px 48px;
  position: relative;
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
`;
export const Design = styled.div`
  margin: 0px 0px 48px;
  margin-bottom: 3rem !important;
  position: relative;
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
`;
export const MobileApplication = styled.div`
  margin: 0px 0px 48px;
  margin-bottom: 3rem !important;
  position: relative;
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
`;
export const WebDevelopment = styled.div`
  margin: 0px 0px 48px;
  margin-bottom: 3rem !important;
  position: relative;
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
`;
